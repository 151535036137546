<template>
  <div
    :class="[
      `c-timer`,
      classBase,
      { 'is-pulsing': isPulsing },
      { 'is-prePulsing': isPrePulsing },
    ]"
  >
    <span class="c-timer_icon"><c-icon name="clock" /></span>
    <span class="c-timer_time">{{ stopwatch }}</span>
  </div>
</template>

<script>
import cIcon from "@components/c-icon/c-icon.vue";
export default {
  name: "c-timer",
  components: { cIcon },
  props: {
    classBase: {
      default: "",
    },
    time: {
      default: 150,
    },
  },
  computed: {
    stopwatch() {
      const min = Math.floor(this.currentTime / 60);
      const sec = this.currentTime % 60;
      return `${min}:${String(sec).padStart(2, "0")}`;
    },
    passedTime() {
      return this.time - this.currentTime;
    },
    isPulsing() {
      return this.currentTime <= 15;
    },
    isPrePulsing() {
      return this.currentTime == 30;
    },
  },
  emits: ["ended"],
  data() {
    return {
      currentTime: 0,
      intervalId: 0,
    };
  },
  methods: {
    reset() {
      this.currentTime = this.time;
      if (this.intervalId !== undefined) clearInterval(this.intervalId);
    },
    start() {
      this.intervalId = setInterval(() => {
        if (--this.currentTime <= 0) this.end();
      }, 1000);
    },
    end() {
      this.currentTime = 0;
      clearInterval(this.intervalId);
      this.$emit("ended");
    },
    pause() {
      clearInterval(this.intervalId);
    },
  },
};
</script>

<style lang="scss" scoped>
.c-timer {
  display: flex;
  align-items: center;
  color: $white;
  font-size: 1.6rem;
  font-weight: bold;

  &.is-pulsing {
    animation-name: pulse;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  &.is-prePulsing {
    animation-name: pulse;
    animation-duration: 1s;
  }
}

.c-timer_icon {
  display: flex;
  margin-right: 0.8rem;

  & svg {
    @include size(1.5rem);

    .c-timer.is-pulsing & {
      animation-name: pulseColorIcon;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      fill: #f7b167;
    }
  }
}

.c-timer_time {
  transition: color 300ms;

  .c-timer.is-pulsing & {
    animation-name: pulseColor;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    color: #f7b167;
  }
}

@keyframes pulseColor {
  from {
    color: $white;
  }

  50% {
    color: #f7b167;
  }

  to {
    color: $white;
  }
}

@keyframes pulseColorIcon {
  from {
    fill: $white;
  }

  50% {
    fill: #f7b167;
  }

  to {
    fill: $white;
  }
}
</style>
