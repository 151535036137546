import { API } from "aws-amplify";
import awsconfig from "@/aws-exports";
import { listGames } from "../graphql/queries";
import { createGame, updateGame } from "../graphql/mutations";
import example from "@/example.json";
API.configure(awsconfig);
export const getPlays = async () => {
  const plays = await API.graphql({
    query: listGames,
  });
  return plays.data?.listGames?.items;
};
export const createPlays = async () => {
  const plays = await getPlays();
  for (const g of example) {
    if (plays.data.listGames.items.some((p) => p.gameID === g.id)) {
      //UPDATE
      await API.graphql({
        query: updateGame,
        variables: {
          input: {
            gameID: g.id,
            questions: g.questions.map((q) => JSON.stringify(q)),
          },
        },
      });
    } else {
      //CREATE
      await API.graphql({
        query: createGame,
        variables: {
          input: {
            gameID: g.id,
            questions: g.questions.map((q) => JSON.stringify(q)),
          },
        },
      });
    }
  }

  return await getPlays();
};

export const Analytic = {
  won: async (gameID) => {
    await API.graphql({
      query: updateGame,
      variables: {
        input: {
          id: gameID,
          won: 1,
        },
      },
    });
  },
  play: async (gameID) => {
    await API.graphql({
      query: updateGame,
      variables: {
        input: {
          id: gameID,
          played: 1,
        },
      },
    });
  },
};
