<template>
  <div :class="['home_wrap', { 'is-backgrounded': isBackgrounded }]">
    <div class="home_header">
      <c-header @startTutorial="onStartTutorial" ref="header" />
    </div>

    <div class="home_swiper">
      <c-swiper :direction="direction" v-model="active" class="swiper">
        <c-swiper-item key="start">
          <v-pages
            @start="onStart"
            :dataLoading="dataLoading"
            :title="pageTitle"
            :description="pageDescription"
            :showSub="pageShowSub"
          />
        </c-swiper-item>
        <c-swiper-item key="game">
          <v-game
            :plays="plays"
            @finish="onFinish"
            @tutorialActive="onTutorialActive"
            :isActive="active === 1"
            ref="game"
          />
          <c-modal @onClose="onModalClose"></c-modal>
        </c-swiper-item>
        <c-swiper-item key="tutorial">
          <v-tutorial @ok="onTutorialClose" />
        </c-swiper-item>
      </c-swiper>
    </div>

    <div class="home_footer">
      <c-footer />
    </div>
  </div>
</template>

<script>
import cSwiper from '@components/c-swiper/c-swiper.vue';
import cSwiperItem from '@components/c-swiper/c-swiper-item.vue';
import cHeader from '@components/c-header/c-header.vue';
import cFooter from '@components/c-footer/c-footer.vue';
import vPages from '@views/v-pages/v-pages.vue';
import vGame from '@views/v-game/v-game.vue';
import vTutorial from '@views/v-tutorial/v-tutorial.vue';
import cModal from '@components/c-modal/c-modal.vue';

// import plays from "@/example.json";
import { getPlays } from '@/library/api.js';
export default {
  name: 'home',
  components: {
    vPages,
    vGame,
    vTutorial,
    cSwiper,
    cSwiperItem,
    cHeader,
    cFooter,
    cModal
  },
  computed: {
    isBackgrounded() {
      return this.active === 0;
    },
    pageView() {
      if (this.pageShowSub === false) {
        return 'gameover';
      } else if (this.pageShowSub === true) {
        return 'gamewon';
      } else {
        return 'home';
      }
    }
  },
  data() {
    return {
      active: 0,
      direction: 'rtl',
      pageTitle: undefined,
      pageDescription: undefined,
      pageShowSub: undefined,
      dataLoading: false,
      showModal: true,
      plays: []
    };
  },
  methods: {
    initData() {
      this.pageTitle = 'Game online';
      this.pageDescription =
        'Indovina le 15 parole entro 2 minuti e 30 secondi spostando le lettere.';
    },
    onStart(args) {
      // Passiamo lo stato della modale al v-game per sapere se far paritre o no il timer
      this.$refs.game.init({ ...args, view: this.pageView, modal: this.showModal });
      this.active = 1;
    },
    onFinish({ title, description, win }) {
      this.pageTitle = title;
      this.pageDescription = description;
      this.pageShowSub = win;
      this.active = 0;
    },
    onTutorialActive(active) {
      this.$refs.header.isUserDifficulty = active;
    },
    onTutorialClose() {
      this.active = 0;
    },
    onStartTutorial() {
      if (this.active === 2) {
        this.active = 0;
      } else {
        this.active = 2;
        this.$refs.header.isUserDifficulty = false;
      }
    },
    onModalClose() {
      this.$refs.game.startTimer();
      this.showModal = false;
    },
    async getConfigs() {
      this.dataLoading = true;
      this.plays = await getPlays();
      this.plays = this.plays.map((p) => ({
        ...p,
        questions: p.questions.map((q) => JSON.parse(q))
      }));
      this.dataLoading = false;
    }
  },
  created() {
    this.getConfigs();
    this.initData();
  }
};
</script>

<style lang="scss" scoped>
.home_wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
  background-image: linear-gradient(to top, #31458c, #828eb9);
}

.home_swiper {
  flex: 1;
  height: 100%;
  position: relative;
  &::before {
    content: '';
    display: block;
    @include pos(0, all);
    opacity: 0;
    background-image: url('~@/assets/img/bg-page.png');
    background-position: bottom left;
    background-size: 100% auto;
    background-repeat: no-repeat;
    z-index: 1;
    transition: opacity 700ms;
  }
  .home_wrap.is-backgrounded &::before {
    opacity: 1;
  }
}

.swiper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  z-index: 9;
  min-height: calc(100vh - 3rem - 11.9rem); // - footer ed header
  @include mq($bp-sm) {
    min-height: calc(100vh - 6.2rem - 8.7rem); // - footer ed header
  }

  @include mq($bp-lg) {
    min-height: calc(100vh - 6.2rem - 6.6rem); // - footer ed header
  }
}
</style>
