<template>
  <button
    @click="onClick"
    :class="[`c-button`, classBase, { 'is-disabled': disabled || loading }]"
  >
    <template v-if="loading">
      <span class="c-button_loaderWrap">
        <div class="c-button_loader">
          <div class="c-button_loader_msk c-button_loader_msk-right">
            <div class="c-button_loader_rotatingEl"></div>
          </div>

          <div class="c-button_loader_msk c-button_loader_msk-left">
            <div class="c-button_loader_rotatingEl"></div>
          </div>
        </div>
      </span>
    </template>
    <template v-else>
      <span class="c-button_label" v-if="label">
        {{ label }}
      </span>

      <span class="c-button_icon" v-if="icon">
        <c-icon :name="icon" />
      </span>
    </template>
  </button>
</template>

<script>
import cIcon from "@components/c-icon/c-icon.vue";
export default {
  name: "c-button",
  components: { cIcon },
  props: {
    /**
     * Label visualizzata dentro al componente
     */
    label: String,
    classBase: String,
    icon: String,
    disabled: Boolean,
    href: String,
    target: String,
    loading: Boolean,
  },
  emits: [
    /**
     * Evento di click sul button
     */
    "click",
  ],
  methods: {
    onClick(e) {
      if (this.href !== undefined) {
        window.open(this.href, this.target || "_blank");
      }
      this.$emit("click", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.c-button {
  background-color: $black;
  border-radius: 0.2rem;
  border: none;
  color: $white;
  fill: $white;
  padding: 1rem 2rem;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  min-height: 4.2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  // Variation blue
  &-blue {
    background-color: #26276d;
  }

  // Variation width 160px
  &-w160px {
    width: 16rem;
  }

  &-refresh {
    padding-bottom: 0.7rem;
  }

  &.is-disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &-bgOrange {
    background-color: orange;
  }

  &-secondary {
    border: 0.1rem solid rgba(0, 0, 0, 0.3);
    background: rgba(0, 0, 0, 0.2);
  }
}

.c-button_label {
  text-transform: uppercase;
  font-size: 1.8rem;
}

.c-button_icon {
  & svg {
    @include size(2rem);
  }
}

//============================================
// Circular loading
//============================================

// Variables
//============================================

$loading-diameter: 1.6rem;

// Wrapper
//============================================

.c-button_loader {
  position: relative;
  width: $loading-diameter;
  height: $loading-diameter;
}

.c-button_loader-margin0auto {
  margin: 0 auto;
}

// Light variation
//.c-button_loader-light

// Gray-light variation
//.c-button_loader-graylight

// Mask
//============================================

.c-button_loader_msk {
  position: relative;
  float: right;
  overflow: hidden;
  width: $loading-diameter / 2;
  height: $loading-diameter;

  &-right {
    border-radius: 0 ($loading-diameter / 2) ($loading-diameter / 2) 0;
  }

  &-left {
    border-radius: ($loading-diameter / 2) 0 0 ($loading-diameter / 2);
  }
}

// Rotating elements
//============================================

.c-button_loader_rotatingEl {
  position: absolute;
  width: $loading-diameter / 2;
  height: $loading-diameter;
  background: $white;
  transform: rotate(180deg);

  // Light variation
  .c-button_loader-light & {
    background: $gray-asphalt;
  }

  .c-button_loader-graylight & {
    background: $gray-light;
  }

  .c-button_loader_msk-right & {
    border-radius: 0 ($loading-diameter / 2) ($loading-diameter / 2) 0;
    transform-origin: 0 50%;
    transform: rotate(360deg);
    animation: anim-rotatingEl-right 1000ms linear infinite;
  }

  .c-button_loader_msk-left & {
    border-radius: ($loading-diameter / 2) 0 0 ($loading-diameter / 2);
    transform-origin: 100% 50%;
    animation: anim-rotatingEl-left 1000ms linear infinite;
  }
}

@keyframes anim-rotatingEl-left {
  0% {
    transform: rotate(180deg);
  }

  33% {
    transform: rotate(360deg);
  }

  50% {
    transform: rotate(480deg);
  }

  100% {
    transform: rotate(540deg);
  }
}

@keyframes anim-rotatingEl-right {
  0% {
    transform: rotate(360deg);
  }

  28% {
    transform: rotate(500deg);
  }

  33% {
    transform: rotate(540deg);
  }

  85% {
    transform: rotate(620deg);
  }

  100% {
    transform: rotate(720deg);
  }
}
</style>
