import { Analytic as Api } from "@/library/api";
const event = async (view, name, value) => {
  const tc_vars_event = {
    event_id: "hidden_event",
    event_source: "games-solo1lettera",
    event_name: `${view}_${name}`,
    event_value: value,
  };
  console.debug("Launch analytic event:", tc_vars_event);
  window.tc_events_1(this, "hidden_event", tc_vars_event);
  if (name === "start") {
    play(value);
  } else if (name === "won") {
    won(value);
  }
};
const won = async (gameID) => {
  try {
    await Api.won(gameID);
  } catch (e) {
    console.error("Analytics not sent: ", e);
  }
};
const play = async (gameID) => {
  try {
    await Api.play(gameID);
  } catch (e) {
    console.error("Analytics not sent: ", e);
  }
};
export const Analytic = {
  event,
  play,
  won,
};
