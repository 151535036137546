<template>
  <div :class="['swiper-item', { 'is-absolute': !isActive }]" v-show="isActive">
    <slot />
  </div>
</template>

<script>
import { computed, inject, watchEffect, getCurrentInstance } from 'vue';
export default {
  name: 'c-swiper-item',
  setup() {
    const instance = getCurrentInstance();
    const { tabs, active } = inject('tabsState');

    const index = computed(() =>
      tabs.value.findIndex((target) => target.uid === instance.uid)
    );
    const isActive = computed(() => index.value === active.value);

    watchEffect(() => {
      if (index.value === -1) {
        tabs.value.push(instance);
      }
    });

    return {
      isActive
    };
  }
};
</script>
<style lang="scss" scoped>
.swiper-item {
  flex: 1;
  &.is-absolute {
    @include pos(0, all);
    height: 100%;
    width: 100%;
  }
}
</style>
