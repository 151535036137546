<template>
  <div class="c-modal" v-show="show" @click="close">
    <div class="hint">
      <span class="c-modal_title">{{ title }}</span>
      <span class="c-modal_description">{{ description }}</span>
      <div class="c-modal_example">
        <img class="c-modal_swipeGif" src="@/assets/img/switch.gif" />
      </div>
      <c-button @click.stop="close" label="HO CAPITO"></c-button>
    </div>
  </div>
</template>

<script>
import cButton from '@components/c-button/c-button.vue';

export default {
  name: 'c-modal',
  components: { cButton },
  data() {
    return {
      show: true,
      title: 'NOVITÀ',
      description: 'Ora puoi anche scambiare le lettere selezionandole.'
    };
  },
  methods: {
    close(e) {
      this.show = false;
      this.$emit('onClose', e);
    }
  }
};
</script>

<style lang="scss" scoped>
.c-modal {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  cursor: pointer;
  .hint {
    width: 90%;
    @include mq($bp-sm) {
      width: 360px;
    }
    min-height: 20%;
    z-index: 101;
    color: white;
    border-radius: 10px;
    background-color: #31458c;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0.25rem;
    text-align: center;
    & * {
      margin: 1rem 0;
    }
    .c-modal_title {
      font-weight: bold;
      font-size: 3rem;
    }
    .c-modal_description {
      font-size: 1.8rem;
    }
    .c-modal_swipeGif {
      width: 100%;
    }
  }
}
</style>
