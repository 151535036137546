<template>
  <div :class="[`v-game`, classBase, { 'is-freezed': isFreezed }]">
    <div class="container">
      <div class="v-game_head">
        <div class="v-game_headTop">
          <div class="v-game_timer">
            <c-timer @ended="onTimeEnd" ref="timer" />
          </div>

          <div class="v-game_counter">
            <c-progress-bar :totalSteps="questions.length" ref="progessBar" />
          </div>

          <div class="v-game_chance">
            <c-lives :totalLives="totalLives" :lives="lives" />
          </div>
        </div>

        <div class="v-game_counter">
          <span class="v-game_counterText">
            {{ currentQuestionIndex }}/{{ questions.length }}
          </span>
        </div>
      </div>

      <div class="v-game_question">
        <span class="v-game_questionText"> </span>
      </div>

      <div :class="['v-game_answer', getAnswerStatusClass()]">
        <draggable
          v-model="answerLetters"
          item-key="id"
          :class="[
            'v-game_answer_lettersWrap',
            { 'is-full': draggingOption && answerReachedMaxLength }
          ]"
          data-maxchar="Troppe lettere"
          v-bind="dragOptions"
          :group="dragGroupAnswer"
          :move="handleMove"
          @start="draggingAnswer = true"
          @end="
            draggingAnswer = false;
            tutorialAlertAbort();
          "
        >
          <template #item="{ element, index }">
            <div
              class="v-game_answer_letter"
              @click="setLetterIndex(index, 'answers')"
            >
              <c-letter
                :label="element.letter"
                :hover="!draggingAnswer && !draggingOption"
                :classBase="element.type === 'option' ? 'c-letter-option' : ''"
                :selected="index === selectedAnswerIndex"
                :swapped="index === tempAnswer && isSwapped"
              />
            </div>
          </template>
        </draggable>
      </div>

      <div class="v-game_options">
        <draggable
          v-model="optionsLetters"
          item-key="id"
          :class="[
            'v-game_options_lettersWrap',
            { 'is-full': draggingAnswer && answerReachedMinLength }
          ]"
          data-maxchar="Troppe lettere"
          v-bind="dragOptions"
          :group="dragGroupOptions"
          :move="handleMove"
          @start="draggingOption = true"
          @end="
            draggingOption = false;
            tutorialAlertAbort();
          "
        >
          <template #item="{ element, index }">
            <div
              class="v-game_options_letter"
              @click="setLetterIndex(index, 'options')"
            >
              <c-letter
                :label="element.letter"
                :hover="!draggingOption && !draggingAnswer"
                :classBase="element.type === 'option' ? 'c-letter-option' : ''"
                :selected="index === selectedOptionIndex"
                :swapped="index === tempOption && isSwapped"
              />
            </div>
          </template>
        </draggable>
      </div>

      <div class="v-game_btActions">
        <div
          class="v-game_btActions_button"
          v-for="(button, i) in btnActions"
          :key="i"
        >
          <c-button v-bind="button" @click="() => onBtnClick(button)" />
        </div>
      </div>
    </div>

    <!-- <div class="debug">
      <p>{{ selectedAnswerIndex }} / {{ selectedOptionIndex }}</p>
      <p>
        {{ answerLetters.map((l) => l.letter) }} <br />
        {{ optionsLetters.map((l) => l.letter) }}
      </p>
      <p>{{ tempAnswer }}/{{ tempOption }}</p>
      <p>{{ currentQuestionAnswer }}</p>
    </div> -->
  </div>
</template>

<script>
import cLetter from '@components/c-letter/c-letter.vue';
import cButton from '@components/c-button/c-button.vue';
import cTimer from '@components/c-timer/c-timer.vue';
import draggable from 'vuedraggable';
import cLives from '@components/c-lives/c-lives.vue';
import cProgressBar from '@components/c-progressBar/c-progressBar.vue';
import humanizeDuration from 'humanize-duration';
import Typewriter from 'typewriter-effect/dist/core';
import { Analytic } from '@/analytics';
import store from 'store';

export default {
  name: 'v-game',
  components: {
    cLetter,
    cButton,
    cTimer,
    cProgressBar,
    draggable,
    cLives
  },
  props: {
    plays: Array,
    classBase: String,
    isActive: Boolean,
    totalLives: {
      default: 3
    }
  },
  computed: {
    progressBarPercentage() {
      return Math.floor(
        (this.currentQuestionIndex / this.questions.length) * 100
      );
    },
    currentQuestionText() {
      return this.questions[this.currentQuestionIndex]?.question;
    },
    currentQuestionAnswer() {
      return this.questions[this.currentQuestionIndex]?.answer.toLowerCase();
    },
    currentQuestionSuggestion() {
      return this.questions[
        this.currentQuestionIndex
      ]?.suggestion.toLowerCase();
    },
    currentPlay() {
      return this.plays.find((p) => p.id === this.currentPlayID);
    },
    questions() {
      return this.currentPlay ? this.currentPlay.questions : [];
    },
    dragOptions() {
      return {
        animation: 200,
        forceFallback: true,
        swap: true,
        fallbackTolerance: 5,
        touchStartThreshold: 5
      };
    },
    dragGroupAnswer() {
      return {
        put: !this.answerReachedMaxLength
      };
    },
    dragGroupOptions() {
      return {
        put: !this.answerReachedMinLength
      };
    },
    answerReachedMaxLength() {
      return this.answerLetters?.length >= 8;
    },
    answerReachedMinLength() {
      return this.answerLetters?.length <= 1;
    },
    statusTransitionDuration() {
      return 1500;
    },
    userAnswer() {
      return this.answerLetters
        ?.map((l) => l.letter)
        .join('')
        .toLowerCase();
    },
    btnActions() {
      return [
        {
          icon: 'refresh',
          event: 'initLetters',
          classBase: 'c-button-refresh',
          disabled: this.currentQuestionSuggestion === this.userAnswer
        },

        {
          label: 'Conferma',
          event: 'confirm'
        }
      ];
    },
    urlSettings() {
      return new URLSearchParams(window.location.hash.slice(1));
    },
    canSwap() {
      return (
        this.selectedAnswerIndex != null && this.selectedOptionIndex != null
      );
    }
  },
  watch: {
    canSwap(newValue, oldValue) {
      if (newValue) this.swapLetters();
    },
    isActive: {
      immediate: true,
      handler(v) {
        if (!v) {
          this.$refs.timer?.pause();
        }
      }
    },
    currentQuestionIndex: {
      handler() {
        this.initLetters();
      }
    },
    currentQuestionText(v) {
      new Typewriter('.v-game_questionText', {
        strings: v,
        autoStart: true,
        delay: 30,
        cursor: ''
      });
    }
  },
  data() {
    return {
      answerStatus: undefined,
      draggingAnswer: false,
      draggingOption: false,
      currentQuestionIndex: 0,
      selectedAnswerIndex: null,
      selectedOptionIndex: null,
      currentPlayID: 0,
      isFreezed: false,
      lives: 3,
      counter: {
        current: 1,
        total: 12
      },
      answerLetters: [],
      optionsLetters: [],
      tempAnswer: null,
      tempOption: null,
      isSwapped: false
    };
  },
  methods: {
    swapLetters() {
      [
        this.answerLetters[this.selectedAnswerIndex],
        this.optionsLetters[this.selectedOptionIndex]
      ] = [
        this.optionsLetters[this.selectedOptionIndex],
        this.answerLetters[this.selectedAnswerIndex]
      ];
      this.isSwapped = true;
      setTimeout(() => {
        this.resetTemporaryLetters();
      }, 100);
      this.resetSelectedLetters();
    },
    resetTemporaryLetters() {
      this.isSwapped = false;
      this.tempAnswer = null;
      this.tempOption = null;
    },
    resetSelectedLetters() {
      this.selectedAnswerIndex = null;
      this.selectedOptionIndex = null;
      this.draggingOption = true;
    },
    setLetterIndex(index, list) {
      if (list === 'answers') {
        this.selectedAnswerIndex =
          index != this.selectedAnswerIndex ? index : null;
        this.tempAnswer = this.tempAnswer = this.selectedAnswerIndex;
      }
      if (list === 'options') {
        this.selectedOptionIndex =
          index != this.selectedOptionIndex ? index : null;
        this.tempOption = this.selectedOptionIndex;
      }
    },
    handleMove() {
      // When the user drag a letter, clear all selected indexes
      this.resetSelectedLetters();
    },
    init(args) {
      this.lives = this.totalLives;
      this.selectPlay(args?.repeat);
      Analytic.event(
        args?.view,
        args?.repeat ? 'tryagain' : 'start',
        this.currentPlayID
      );
      this.initPlay();
      // Reset progress bar on init
      this.$refs.progessBar.resetStep();
      // Reset timer bar on init
      this.$refs.timer.reset();

      // Se la modale é già stata chiusa, fai partire il timer
      if (!args.modal) this.startTimer();
    },
    startTimer() {
      this.$refs.timer.start();
    },
    initPlay() {
      this.currentQuestionIndex = this.urlSettings.has('index')
        ? parseInt(this.urlSettings.get('index'))
        : 0;
      this.initLetters();
      this.tutorialAlert();
    },
    initLetters() {
      this.answerLetters = this.questions[this.currentQuestionIndex]?.suggestion
        .split('')
        .map((c) => ({ letter: c, type: 'answer' }));
      this.optionsLetters = this.questions[
        this.currentQuestionIndex
      ]?.options.map((c) => {
        if (c.length === 2) {
          const ACCENTS = ['á', 'à', 'é', 'è', 'í', 'ì', 'ó', 'ò', 'ú', 'ù'];
          return {
            letter: ACCENTS.includes(c[0]) ? c[1] : c[0],
            type: 'option',
            withAccent: true
          };
        } else {
          return { letter: c, type: 'option', withAccent: false };
        }
      });
    },
    selectPlay(repeat) {
      if (this.urlSettings.has('id')) {
        this.currentPlayID = this.urlSettings.get('id');
      } else if (this.urlSettings.has('gameID')) {
        this.currentPlayID = this.urlSettings.get('gameID');
      } else if (repeat !== true) {
        try {
          const data = store.get('solo1lettera') || {};
          const wonPlays = data.wonPlays || [];
          const lastPlays = data.lastPlays || [];
          let filteredPlays = this.plays.filter(
            (p) => !wonPlays.some((lp) => lp === p.id)
          );
          if (filteredPlays.length > 1) {
            filteredPlays = filteredPlays.filter(
              (p) => !lastPlays.some((lp) => lp === p.id)
            );
          }
          this.currentPlayID =
            filteredPlays[Math.floor(Math.random() * filteredPlays.length)].id;

          const lastPlaysCorrectLength = Math.floor(
            (this.plays.length - wonPlays.length) / 2
          );

          if (lastPlays.length >= lastPlaysCorrectLength) {
            lastPlays.splice(
              0,
              lastPlays.length -
                Math.floor((this.plays.length - wonPlays.length) / 2) +
                1
            );
          }
          lastPlays.push(this.currentPlayID);

          store.set('solo1lettera', { ...data, lastPlays });
        } catch (e) {
          console.error(e);
          this.currentPlayID =
            this.plays[Math.floor(Math.random() * this.plays.length)]?.id;
        }
      }
    },
    onTimeEnd() {
      this.$emit('finish', {
        title: 'Il tempo è scaduto!',
        description: 'Peccato, prova a fare meglio.',
        win: false
      });
    },
    getAnswerStatusClass() {
      // Se non ancora risposto
      if (this.answerStatus === undefined) {
        return '';
      } else if (this.answerStatus) {
        // Se ha risposto correttamente
        return 'is-correct';
      } else {
        // Se ha sbagliato
        return 'is-wrong';
      }
    },
    confirm() {
      this.isFreezed = true;
      this.$refs.timer.pause();

      if (
        this.userAnswer.normalize('NFD').replace(/[\u0300-\u036f]/g, '') ==
        this.currentQuestionAnswer
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      ) {
        this.answerLetters = Array.from(this.currentQuestionAnswer).map(
          (l) => ({ letter: l })
        );
        this.answerStatus = true;

        // Increment progress bar step
        this.$refs.progessBar.incrementStep();

        setTimeout(() => {
          this.rightAnswer();
          this.answerStatus = undefined;
          this.isFreezed = false;
        }, this.statusTransitionDuration);
      } else {
        this.answerStatus = false;
        setTimeout(() => {
          this.wrongAnswer();
          this.answerStatus = undefined;
          this.isFreezed = false;
        }, this.statusTransitionDuration);
      }
    },
    rightAnswer() {
      if (this.currentQuestionIndex + 1 === this.questions.length) {
        this.saveWonID();
        this.$emit('finish', {
          title: 'Congratulazioni',
          description: `Hai totalizzato 15 risposte corrette e ${
            this.totalLives - this.lives
          } errori in ${humanizeDuration(this.$refs.timer.passedTime * 1000, {
            language: 'it',
            delimiter: ' e '
          })}.`,
          win: true
        });
        Analytic.event('game', 'won', this.currentPlayID);
      } else {
        this.currentQuestionIndex++;
        this.$refs.timer.start();
      }
    },
    wrongAnswer() {
      if (--this.lives < 1) {
        // Mettendo -- prima, fa prima la sottrazione alla variabile e successivamente il controllo.
        this.$emit('finish', {
          title: 'Game over',
          description: `Hai commesso 3 errori, prova a fare meglio.`,
          win: false
        });
        Analytic.event('game', 'gameover', this.currentPlayID);
      } else {
        this.initPlay();
        // Reset progress bar
        this.$refs.progessBar.resetStep();
        this.$refs.timer.start();
      }
    },
    saveWonID() {
      try {
        const data = store.get('solo1lettera') || {};
        const wonPlays = data.wonPlays || [];
        if (wonPlays.length >= this.plays.length - 1) {
          store.set('solo1lettera', { ...data, wonPlays: [] });
        } else {
          wonPlays.push(this.currentPlayID);
          store.set('solo1lettera', { ...data, wonPlays });
        }
      } catch (e) {
        console.error(e);
      }
    },
    onBtnClick({ event }) {
      this[event]();
    },
    onAnswerAdd(e) {
      if (this.answerLetters?.length > 8) {
        this.optionsLetters.splice(
          e.oldIndex,
          0,
          this.answerLetters[e.newIndex]
        );
        this.answerLetters.splice(e.newIndex, 1);
      }
    },
    onOptionsAdd(e) {
      if (this.answerLetters?.length < 2) {
        this.answerLetters.splice(
          e.oldIndex,
          0,
          this.optionsLetters[e.newIndex]
        );
        this.optionsLetters.splice(e.newIndex, 1);
      }
    },
    tutorialAlert() {
      if (!this.tutorialTimeoutID) {
        this.tutorialTimeoutID = setTimeout(() => {
          this.$emit('tutorialActive', true);
        }, 10000);
      }
    },
    tutorialAlertAbort() {
      if (this.tutorialTimeoutID > 0) {
        clearTimeout(this.tutorialTimeoutID);
        this.$emit('tutorialActive', false);
        this.tutorialTimeoutID = -1;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-game {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  position: relative;
  padding-top: $space;
  padding-bottom: $space-double;
  min-height: 100%;

  &.is-freezed::before {
    content: '';
    display: block;
    @include pos(0, all);
    position: fixed;
    z-index: 999;

    &:hover {
      background-color: transparent;
    }
  }
}

// Head
//===============================
.v-game_head {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mq($bp-sm) {
    width: 68rem;
    margin: 0 auto;
  }

  @include mq($bp-md) {
    width: 73rem;
    margin: 0 auto;
  }

  .v-game_headTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.v-game_counter {
  width: 70%;
  margin: 0 auto;
  @media (max-width: 600px) {
    width: 50%;
  }
}

.v-game_counterText {
  display: block;
  color: $white;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
}

.v-game_chance {
  margin-top: 0.2rem;
}

// Question
//===============================
.v-game_question {
  margin-top: $space-double;
  padding-left: $space-half;
  padding-right: $space-half;
  text-align: center;

  @media (max-width: 360px) {
    margin-top: $space-oneAndHalf;
  }

  @include mq($bp-sm) {
    margin-top: $space-triple;
    width: 68rem;
    margin-left: auto;
    margin-right: auto;
  }

  @include mq($bp-md) {
    width: 73rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.v-game_questionText {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  font-size: 2rem;
  min-height: 5.5rem;

  @media (max-width: 360px) {
    font-size: 1.9rem;
  }

  @media (max-width: 350px) {
    font-size: 1.9rem;
  }

  @include mq($bp-sm) {
    font-size: 2.4rem;
  }
}

// Answer
//===============================
.v-game_answer {
  display: flex;
  margin-top: $space-oneAndHalf;
  margin-left: -2.6rem;
  margin-right: -2.6rem;

  @media (max-width: 360px) {
    margin-top: $space;
  }

  @include mq($bp-sm) {
    margin-top: $space-double;
  }
}

.v-game_answer_lettersWrap {
  position: relative;
  display: flex;
  margin: 0 auto;
  padding: 1rem;
  background-color: rgba($black, 0.2);

  &.is-full {
    background-color: transparent;
  }

  &.is-full::before {
    content: '';
    display: block;
    @include pos(0, all);
    // background-color: rgba(#31417d, 0.9);
    border: 0.1rem dashed #8fa3e9;
    z-index: 9;
  }

  &.is-full::after {
    content: attr(data-maxchar);
    display: block;
    color: $white;
    text-transform: uppercase;
    @include font-weight($fw-bold, $font-alt);
    @include center-transform();
    z-index: 10;
  }
}

.v-game_answer_letter {
  margin-left: 0.2rem;
  margin-right: 0.2rem;

  @include mq($bp-sm) {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }

  .v-game_answer_lettersWrap.is-full & {
    filter: blur(0.2rem);
    opacity: 0.08;
  }

  // Tassello che viene trascinato
  &.sortable-ghost {
    opacity: 0;
  }

  // Tassello draggato
  &.sortable-drag {
    box-shadow: 0 1.6rem 1.6rem rgba($black, 0.6);
  }
}

// Option
//===============================
.v-game_options {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.v-game_options_lettersWrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 24rem;

  @media (max-width: 360px) {
    width: 23rem;
  }

  @media (max-width: 350px) {
    width: 21.5rem;
  }

  @include mq($bp-sm) {
    width: 36rem;
  }

  &.is-full::before {
    content: '';
    display: block;
    @include pos(-0.6rem, all);
    // background-color: rgba(#31417d, 0.9);
    border: 0.1rem dashed #8fa3e9;
    z-index: 9;
  }

  &.is-full::after {
    content: attr(data-maxchar);
    display: block;
    color: $white;
    text-transform: uppercase;
    font-size: 1.4rem;
    @include font-weight($fw-bold, $font-alt);
    @include center-transform();
    z-index: 10;
  }
}

.v-game_options_letter {
  margin: 0.4rem;

  // Tassello che viene trascinato
  &.sortable-ghost {
    opacity: 0;
  }

  // Tassello draggato
  &.sortable-drag {
    box-shadow: 0 1.6rem 1.6rem rgba($black, 0.6);
  }

  .v-game_options_lettersWrap.is-full & {
    filter: blur(0.2rem);
    opacity: 0.08;
  }
}

// Button actions
//===============================
.v-game_btActions {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.v-game_btActions_button {
  &:not(:first-child) {
    margin-left: $space-half;
  }
}

.debug {
  position: absolute;
  bottom: -100px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.5rem;
  color: white;
  text-align: center;
}

.let {
  width: 50px;
  height: 50px;
  background-color: gray;
  color: white;
}
</style>
