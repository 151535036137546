<template>
  <div
    :class="[
      'c-letter',
      classBase,
      { 'is-hover': hover },
      { selected: selected },
      { swapped: swapClass }
    ]"
    ref="letter"
  >
    <span :class="`c-letter_label ` + classLabel">
      {{ label }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'c-letter',
  props: {
    label: {
      default: 'Label'
    },
    classBase: {
      default: ''
    },
    classLabel: {
      default: 'u-p-abs-centred'
    },
    hover: {
      default: true
    },
    swapped: {
      default: false
    },
    selected: {
      default: false
    }
  },
  data() {
    return {
      swapClass: false
    };
  },
  watch: {
    swapped(hasSwapped, resetSwapped) {
      if (hasSwapped) this.animate();
    }
  },
  methods: {
    onClick(e) {
      // console.log('Click', new Date().getSeconds());
      // this.$emit('onClick', e);
    },
    animate() {
      this.swapClass = true;
      this.$refs.letter.addEventListener('animationend', () => {
        this.swapClass = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.c-letter {
  user-select: none;
  position: relative;
  @include size(4rem);

  border-radius: 0.2rem;
  background-color: $black;
  // cursor: grab;

  @media (max-width: 360px) {
    @include size(3.8rem);
  }

  @media (max-width: 350px) {
    @include size(3.5rem);
  }

  @include mq($bp-sm) {
    @include size(6.4rem);
  }

  &.is-hover:hover {
    background-color: $gray-rat;
    box-shadow: 0 1rem 1rem rgba($black, 0.6);
  }

  &.swapped {
    transform-origin: center;
    animation: swapped 1s ease-in-out;
  }

  &.selected {
    background-color: #333333;
    outline-style: solid;
    outline-width: 2px;
    transition: transform 500ms;
    transform: scale(1.075);
    box-shadow: 0 1rem 1rem rgba($black, 0.6);
    animation: pulse 1.5s ease-out alternate infinite;
  }

  // Variation option
  &-option {
    background-color: #f7b167;
    &.selected {
      background-color: #fcdfa3;
      outline-style: solid;
      outline-width: 3px;
      animation: pulse 1.5s ease-out alternate infinite;
    }
    &.is-hover:hover {
      background-color: #fcdfa3;
    }
  }

  // is-wrong
  $anim-delay: 70ms;

  .is-wrong & {
    background-color: #cf3335;
    transition: background-color 200ms;
    animation-name: headShake;
    animation-duration: 600ms;
  }

  // is-correct
  $anim-delay: 50ms;

  .is-correct & {
    background-color: #87a72d;
    transition: background-color 500ms;
    animation-name: bounce;
    animation-duration: 700ms;
  }

  .is-correct .v-game_answer_letter:nth-child(2) & {
    animation-delay: $anim-delay;
    transition-delay: $anim-delay;
  }

  .is-correct .v-game_answer_letter:nth-child(3) & {
    animation-delay: $anim-delay * 2;
    transition-delay: $anim-delay * 2;
  }

  .is-correct .v-game_answer_letter:nth-child(4) & {
    animation-delay: $anim-delay * 3;
    transition-delay: $anim-delay * 3;
  }

  .is-correct .v-game_answer_letter:nth-child(5) & {
    animation-delay: $anim-delay * 4;
    transition-delay: $anim-delay * 4;
  }

  .is-correct .v-game_answer_letter:nth-child(6) & {
    animation-delay: $anim-delay * 5;
    transition-delay: $anim-delay * 5;
  }

  .is-correct .v-game_answer_letter:nth-child(7) & {
    animation-delay: $anim-delay * 6;
    transition-delay: $anim-delay * 6;
  }

  .is-correct .v-game_answer_letter:nth-child(8) & {
    animation-delay: $anim-delay * 6;
    transition-delay: $anim-delay * 6;
  }
}

.c-letter_label {
  font-size: 2.4rem;
  font-weight: bold;
  text-transform: uppercase;
  color: $white;

  @include mq($bp-sm) {
    font-size: 4rem;
  }

  // Variation option
  .c-letter-option & {
    color: $black;

    .is-wrong & {
      color: $white;
    }

    .is-correct & {
      color: $white;
    }
  }
}

@keyframes pulse {
  0% {
    outline-color: rgba($color: #ffffff, $alpha: 0.2);
  }
  50% {
    outline-color: rgba($color: #ffffff, $alpha: 0.6);
  }
  100% {
    outline-color: rgba($color: #ffffff, $alpha: 0.2);
  }
}

@keyframes swapped {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
