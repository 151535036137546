<template>
  <div class="swiper">
    <transition-group
      :leave-to-class="direction === 'rtl' ? 'slideOutLeft' : 'slideOutRight'"
      :enter-to-class="direction === 'rtl' ? 'slideInRight' : 'slideInLeft'"
      :enter-from-class="direction === 'rtl' ? 'slideInRight' : 'slideInLeft'"
      enter-active-class="animated"
      leave-active-class="animated"
    >
      <slot />
    </transition-group>
  </div>
</template>

<script>
import { provide, computed, ref } from "vue";
/**
 * Move throught pages with animation
 * use v-model="" with Number to change page
 * use direction="rtl" to change transition direction
 */
export default {
  name: "c-swiper",
  props: {
    modelValue: {
      type: [String, Number],
    },
    /** transition direction
     * could be 'rtl' (default) or 'ltr'
     * this property is passed down to c-swiper-item
     */
    direction: {
      validator(value) {
        return ["rtl", "ltr"].includes(value);
      },
      default: "ltr",
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const active = computed(() => props.modelValue);
    const tabs = ref([]);

    function selectTab(tab) {
      emit("update:modelValue", tab);
    }

    provide("tabsState", {
      active,
      tabs,
    });

    return {
      tabs,
      active,
      selectTab,
    };
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.animated {
  transition: 1s;
}
</style>
