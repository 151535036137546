<template>
  <Home />
</template>

<script>
import Home from '@pages/home/home.vue';

export default {
  name: 'App',
  components: {
    Home
  }
};
</script>

<style lang="scss"></style>
