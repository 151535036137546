<template>
  <header
    :class="['c-header', classBase, { 'is-userDifficulty': isUserDifficulty }]"
  >
    <div class="c-header_wrap">
      <div class="c-header_back">
        <c-icon name="arrow-lft" />
      </div>

      <div class="c-header_title">
        <span class="c-header_titleText">Solo1Lettera</span>
        <a href="http://www.rsi.ch" class="c-header_titleLogo"
          ><img src="@/assets/img/logo_rsi.svg"
        /></a>
      </div>

      <div class="c-header_info" @click="$emit('startTutorial')">
        <c-icon name="info" />
      </div>
    </div>
  </header>
</template>

<script>
import cIcon from "@components/c-icon/c-icon.vue";
export default {
  name: "c-header",
  props: {
    classBase: String,
  },
  data() {
    return {
      isUserDifficulty: false,
    };
  },
  components: { cIcon },
};
</script>

<style lang="scss" scoped>
.c-header {
  font-family: $font;
  background-image: linear-gradient(to bottom, $gray-space, $gray-rat);
}

.c-header_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $header-inneWrap-height;
}

.c-header_title {
  display: flex;
}

.c-header_titleText {
  display: flex;
  font-size: 1.8rem;
  color: $white;
}

.c-header_titleLogo {
  display: block;
  padding-left: 1rem;
  margin-left: 1rem;
  border-left: 0.1rem solid $gray-light;
}

.c-header_back {
  visibility: hidden;
  pointer-events: none;
  position: relative;
  @include size(3rem);
  margin-left: 0.8rem;
  cursor: pointer;

  .svg-icon {
    @include size(1.5rem);
    @include center-transform();
  }
}

.c-header_info {
  position: relative;
  @include size(2.2rem);
  margin-right: $space;
  border: 0.1rem solid $white;
  border-radius: 50%;
  cursor: pointer;

  .c-header.is-userDifficulty & {
    animation-name: wobble;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  .svg-icon {
    @include size(1.1rem);
    @include center-transform();
  }
}
</style>
