<template>
  <div class="c-progress-bar">
    <div class="bar" :style="{ width: percentage + '%' }" />
  </div>
</template>

<script>
export default {
  name: 'c-progress-bar',
  props: {
    totalSteps: {
      type: Number
    }
  },
  data() {
    return {
      step: 0
    };
  },
  computed: {
    percentage() {
      return (this.step / this.totalSteps) * 100;
    }
  },
  methods: {
    incrementStep() {
      this.step++;
    },
    resetStep() {
      this.step = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.c-progress-bar {
  width: 100%;
  height: 10px;
  background-color: rgba($color: #000000, $alpha: 0.2);
  color: white;
  border-radius: 10px;
  .bar {
    transition: width 0.65s ease-out;
    border-radius: 10px;
    background-color: #87a72d;
    height: 100%;
  }
}
</style>
