<template>
  <div :class="[`c-lives`, classBase]">
    <span
      :class="[`c-lives_live`, { 'is-full': totalLives - lives <= i }]"
      v-for="(live, i) in totalLives"
      :key="i"
    >
      <span class="c-lives_liveFull"><c-icon name="live-full" /></span>
      <span class="c-lives_liveEmpty"><c-icon name="live-empty" /></span>
    </span>
  </div>
</template>

<script>
import cIcon from '@components/c-icon/c-icon.vue';
export default {
  name: 'c-lives',
  components: {
    cIcon
  },
  props: {
    classBase: String,
    totalLives: {
      default: 3
    },
    lives: {
      default: 3
    }
  }
};
</script>

<style lang="scss" scoped>
.c-lives {
  display: flex;
  justify-content: flex-end;
}

.c-lives_live {
  display: block;
  @include size(1.8rem);
  position: relative;
  &:not(:first-child) {
    margin-left: 0.8rem;
  }
}

.c-lives_liveFull {
  @include pos(50%, top left);
  transform: translateY(calc(-50% - 3rem)) translateX(-50%);
  opacity: 0;
  transition: all 500ms;
  z-index: 9;

  & .svg-icon {
    @include size(1.8rem);
    fill: #f7b167;
  }

  // is-full
  .c-lives_live.is-full & {
    opacity: 1;
    @include pos(50%, top left);
    transform: translateY(-50%) translateX(-50%);
  }
}

.c-lives_liveEmpty {
  @include pos(50%, top left);
  transform: translateY(-50%) translateX(-50%);
  z-index: 1;

  & .svg-icon {
    @include size(1.8rem);
    fill: $white;
  }
}
</style>
