<template>
  <div :class="[`v-tutorial`, classBase]">
    <div class="container">
      <div class="v-tutorial_heading">
        <span class="v-tutorial_description">
          {{ description }}
        </span>
      </div>

      <div class="v-tutorial_example">
        <img class="v-tutorial_exampleImg" src="@/assets/img/tutorial.gif" />
      </div>

      <div class="v-tutorial_heading">
        <span class="v-tutorial_description">
          {{ swipe }}
        </span>
      </div>

      <div class="v-tutorial_example">
        <img class="v-tutorial_swipeGif" src="@/assets/img/switch.gif" />
      </div>

      <div class="v-tutorial_heading">
        <span class="v-tutorial_description">
          {{ note }}
        </span>
      </div>

      <div class="v-tutorial_btActions">
        <span class="v-tutorial_btnOk">
          <c-button v-bind="btnOk" @click="ok" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import cButton from '@components/c-button/c-button.vue';
export default {
  name: 'v-tutorial',
  components: {
    cButton
  },
  data() {
    return {
      subDescription: 'Perché non partecipi al quiz televisivo?'
    };
  },
  props: {
    classBase: String,
    showSub: Boolean,
    btnOk: {
      type: Object,
      default: () => ({
        label: 'Ho capito',
        classBase: 'c-button-w160px'
      })
    },
    description: {
      default: `Trascina le lettere da sopra a sotto, oppure da sotto a sopra, per formare la parola corretta.`
    },
    swipe: {
      default: 'Oppure scambia le lettere selezionandole.'
    },
    note: {
      default: `Attenzione: gli accenti non contano come cambiamento, le lettere si equivalgono. Ad esempio « Ò » è uguale a « O » e viceversa.`
    }
  },
  emits: ['ok'],
  methods: {
    ok() {
      this.$emit('ok');
    }
  }
};
</script>

<style lang="scss" scoped>
.v-tutorial {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  min-height: calc(100vh - 3rem - 11.9rem); // - footer ed header
  @include mq($bp-sm) {
    min-height: calc(100vh - 6.2rem - 8.7rem); // - footer ed header
  }

  @include mq($bp-lg) {
    min-height: calc(100vh - 6.2rem - 6.6rem); // - footer ed header
  }
  background-color: #31458c;
}

// Heading
//===============================
.v-tutorial_heading {
  display: flex;
  justify-content: center;
  padding-top: $space-oneAndHalf;
}

// Description
.v-tutorial_description {
  width: 44rem;
  display: block;
  margin-top: $space;
  font-size: 2rem;
  text-align: center;
  color: $white;
  &:last-child {
    margin-top: 0;
  }
}

// Img
//===============================
.v-tutorial_example {
  display: flex;
  justify-content: center;
  margin-top: $space-double;
}

.v-tutorial_exampleImg {
  width: 35rem;
  @include mq($bp-sm) {
    width: 37rem;
  }
  @include mq($bp-md) {
    width: 37rem;
  }
}
.v-tutorial_swipeGif {
  width: 35rem;
  @include mq($bp-sm) {
    width: 37rem;
  }
  @include mq($bp-md) {
    width: 37rem;
  }
}

// Buttons
//===============================
.v-tutorial_btActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $space-double;
  padding-bottom: $space-double;
}
</style>
