<template>
  <footer class="c-footer">
    <div class="c-footer_wrap">
      <div class="c-footer_description">
        <div class="c-footer_copyright">
          Copyright © RSI <span class="c-footer_year">{{ currentYear }}</span> –
          All rights reserved
        </div>
        <div class="c-footer_disclaimer">
          RSI Radiotelevisione di lingua italiana, succursale della Società
          svizzera di radiotelevisione
        </div>
      </div>
      <div class="c-footer_logo">
        <a href="#" class="c-footer_logoSVG"
          ><img src="@/assets/img/logo_srgssr.svg"
        /></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "c-footer",
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
.c-footer {
  background-color: $gray-wetAsphalt;
}

.c-footer_wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: $space;
}

.c-footer_description {
  font-family: $font;
  font-size: 1.2rem;
  color: #999;

  @include mq($bp-lg) {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
}

.c-footer_disclaimer {
  margin-top: $space-half;

  @include mq($bp-lg) {
    margin-top: 0;
  }
}

.c-footer_logo {
  margin-left: $space;

  @include mq($bp-lg) {
    margin-left: $space-half;
  }
}
</style>
